export const services_data_rare = [
	{
		title: 'Comunes',
		desc: "Tendrán acceso al contenido exclusivo, participarán en la toma de decisiones a través de votaciones, participarán de sorteos, serán invitados a participar de eventos y podrán ser poseedores de al menos una de las botellas de vino creadas por la comunidad.",
		id: 'NFT Consultant Analytics',
		icon: 'common',
	},
	{
		title: 'Raros',
		desc: "Tendrán todos los beneficios comunes, además participarán de sorteos exclusivos, serán invitados a participar de cenas y degustaciones exclusivas para los poseedores de NFTs de alto nivel.",
		id: '0NFT Consultant Analytics',
		icon: 'rare',
	},
	{
		title: 'Épicos',
		desc: "Tendrán todos los beneficios de raros, además podrán ser poseedores de 2 botellas del vino creado y podrán participar en viajes y otras experiencias únicas.",
		id: '1NFT Marketing',
		icon: 'epic',
	},
	{
		title: 'Legendarios',
		desc: "Tendrán todos los beneficios de épicos, además serán quienes participen en la toma de decisiones de alto impacto para el proyecto y podrán ser poseedores de hasta 3 botellas del vino creado.",
		id: '2NFT Strategy',
		icon: 'legendary',
	},
];
