import React, { useEffect } from "react";
import Meta from "../../components/Meta";
import Hero from "../../components/hero/hero";
import {Partners} from "../../components/component";
import TrustedPartner from "../../components/dao/TrustedPartner";
import ProSaleBanner from "../../components/ico-landing/ProSaleBanner";
import Benifits from "../../components/dao/Benifits";
import CallToActions from "../../components/ico-landing/CallToActions";
import CallToActions2 from "../../components/ico-landing/CallToActions2";
import Team from "../../components/ico-landing/Team";
import Faq from "../../components/faq/faq";
import Services from "../../components/services/services";
import NewseLatter from "../../components/blog/newseLatter";

const Home_12 = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.15]");
  }, []);

  return (
    <>
      <Meta title="Digital Wines Club" />
      <Hero id="home"/>       
      <ProSaleBanner id="contador" /> 
      <Services id="beneficios" />
      <CallToActions2 /> 
      { /* <Benifits id="rareza"/> */}
      { /*<Partners id="respaldanhoy"/> */}
      { /* <TrustedPartner id="respaldan" /> */}
      { /* <NewseLatter  id="comprar" />*/}
      <Team id="equipo" /> 
      { /* <Faq  id="faq"/> */}
      { /*<CallToActions />  */}

    </>
  );
};

export default Home_12;
