export const services_data = [
	{
		title: 'Votación en decisiones',
		desc: "Todos podrán votar en decisiones para la creación de la línea de vinos, desde la etiqueta hasta el corcho o el tipo de botella",
		id: '0NFT Consultant Analytics',
		icon: 'email',
	},
	{
		title: 'Acceso a contenido exclusivo',
		desc: "Todos tendrán acceso a contenido exclusivo como por ejemplo, clases de cata, de maridaje, recomendaciones del sommelier mensuales y entrevistas.",
		id: '1NFT Marketing',
		icon: 'video-movie',
	},
	{
		title: 'Sorteos',
		desc: "Todos participarán de sorteos mensuales patrocinados por socios y empresas que apoyan el proyecto.",
		id: '2NFT Strategy',
		icon: 'gift-box',
	},
	{
		title: 'Descuentos',
		desc: "Todos accederán a importantes descuentos en productos de interés para la comunidad.",
		id: '3NFT Technology',
		icon: 'sale',
	},
	{
		title: 'Invitaciones a eventos',
		desc: "Todos estarán invitados a participar de eventos semestrales para compartir un momento con la comunidad.",
		id: '4Advisers & Intermediaries',
		icon: 'shopping',
	},
	{
		title: 'Botella de vino',
		desc: "¡Una vez creada la línea de vinos, todos podrán ser poseedores de al menos 1 de las 3.000 botellas creadas juntos!",
		id: '5ICO Token',
		icon: 'wine-bottle',
	},
];
